import React, { useState, useEffect } from 'react';

import ReportsListFilteredModule from '../modules/ReportsListFilteredModule.js';
import './Box.css';

const LatestReportsBox = ({user, isSmallScreen}) => {

    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>

            <h1 className='box-title'> Latest Reports </h1>

            <ReportsListFilteredModule
            user={user}
            isSmallScreen={isSmallScreen}
            />

        </div>
    );
};

export default LatestReportsBox;