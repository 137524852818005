import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import DualColListElement from '../list_elements/DualColListElement.js';
import './Module.css';
import TypeTag from '../tags/TypeTag.js';
import MedicTag from '../tags/MedicTag.js';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportInfoModule = ({user, reportId, isSmallScreen}) => {
    const [reportData, setReportData] = useState(null);
    const [reportTypes, setReportTypes] = useState(null);
    const [medics, setMedics] = useState(null);

    // function that gets one reports from the server
    const getOneReport = async (reportId) => {
        const url = URLS.GET_ONE_REPORT;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        getOneReport(reportId)
            .then((data) => {
                setReportData(data);
                setReportTypes(data[0].report_type.split(','));
                setMedics(JSON.parse(data[0].medics));
                // medics is an array of JSONs
                // console.log('medics: ', medics[0].response_role);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);


    const createInfoListElement = (elementName, elementValue) => {
        return (
            elementValue !== null && elementValue !== 'null' ? ( // if elementValue is null, display 'insufficient data'
            <DualColListElement
            elementName={elementName}
            elementValue={elementValue}   
            />
            ) : (
            <DualColListElement
            elementName={elementName}
            elementValue='Insufficient Data'
            />
            )
        );
    };

    const convertToLocaleString = (dateString) => {
        const options = { 
            hour: 'numeric', 
            minute: 'numeric', 
            hour12: true, 
            year: 'numeric', 
            month: 'numeric', 
            day: 'numeric'
        };
    
        // if datestring is undefined or null, return 'no data'
        if (dateString === undefined || dateString === null) {
            return 'no data';
        }

        // Print out unconverted date
        // console.log('original dateString:', dateString);
    
        // convert the date string to a valid ISO 8601 date string and add Z to the end to indicate UTC time
        dateString = dateString.replace(' ', 'T');
        dateString = dateString + 'Z';

        // now convert the date string (which represnets a date in GMT) to a date object
        const date = new Date(dateString);
        
        // Print new date
        // console.log('new date:', date); // this is printing out GMT as CDT
    
        return date.toLocaleString([], options);
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div>
                <h2 className='module-title'>Report Information</h2>
            </div>
            {reportData && reportTypes && medics ? (
                <div>
                    {createInfoListElement('Incident #:', reportData[0].incident_number)}
                    {createInfoListElement('Run Began:', convertToLocaleString(reportData[0].call_begin))}
                    {createInfoListElement('Run Ended, Back in Service:', convertToLocaleString(reportData[0].unit_back_in_service))}
                    {createInfoListElement('PCR Completed:', convertToLocaleString(reportData[0].creation_timestamp))}
                    {/* {createInfoListElement('Involved Medics:', medics.map((medic) => medic.crew_member_id).join(', '))} */}
                    
                    <div className='list-element'>
                        <div className='small-div'>
                            <strong>Involved Medics:</strong>
                        </div>
                        <div className='small-div' style={{flexDirection: 'row'}}>
                            {medics.map((medic) => (
                                <MedicTag medicNumber={medic.crew_member_id} isButton={false}/>
                            ))}
                        </div>
                    </div>

                    <div className='list-element'>
                        <div className='small-div'>
                            <strong>Report Type(s):</strong>
                        </div>
                        <div className='small-div' style={{flexDirection: 'row'}}>
                            {reportTypes.map((type) => (
                                <TypeTag reportType={type} isButton={false}/>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportInfoModule;