import axios from 'axios';
const StatusCodes = require('../status-codes/status-codes.js')
const ErrorCodes = require('../error-codes/error-codes.js')
const FormData = require('form-data');


class Network {
    static async post(url, values, callback) {
        try {
            const response = await axios.post(url, values);
            // Handle the response, e.g., redirect or display a success message
            callback(response)
            return
        } catch (error) {
            const response = {
                data: 4000,
                status: 500
            }
            callback(response)
            return;
        }
    }

    static statusCodeOK(statusCode) {
        if (statusCode >= 200 && statusCode <= 299) {
            return true;
        }
        return false;
    }

    static INVALID_RESPONSES_SET = new Set([
        ErrorCodes.EMAIL_IN_USE,
        ErrorCodes.EMAIL_INVALID,
        ErrorCodes.USERNAME_IN_USE,
        ErrorCodes.USER_ALREADY_CREATED,
        ErrorCodes.CODE_NOT_VALID,
        ErrorCodes.USER_NOT_FOUND,
        ErrorCodes.PASSWORD_INCORRECT,
        ErrorCodes.FILE_UPLOAD_FAILED,
        ErrorCodes.REAUTHENTICATION_NEEDED,
        ErrorCodes.EMAIL_DOMAIN_NOT_ALLOWED,
        ErrorCodes.INVALID_TOKEN,
        ErrorCodes.INVALID_CLIENT_VERSION,
        ErrorCodes.INCORRECT_VERIFICATION_CODE,
        ErrorCodes.INVALID_DATA_FORMAT,
        ErrorCodes.INVALID_FILE,
        ErrorCodes.SYSTEM_FAILED
    ]);

    static containsInvalidResponse(responseData) {
        if (Network.INVALID_RESPONSES_SET.has(responseData.toString())) {
            return true;
        }
        return false;
    }

    static checkValidStatusAndResponse(response) {
        const data = response.data
        const status = response.status
        if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
            return false;
        } else if (status === StatusCodes.WAITING_FOR_RESPONSE) {
            return false;
        } else if (!Network.statusCodeOK(status)) {
            return false;
        } else if (Network.containsInvalidResponse(data)) {
            return false;
        }

        return true;
    }

    static fileIsValid(response) {
        const data = response.data
        if (data == ErrorCodes.INVALID_FILE) {
            return false
        } else {
            return true
        }
    }

    static systemDidFail(response) {
        const data = response.data
        if (data == ErrorCodes.SYSTEM_FAILED) {
            return true
        } else {
            return false
        }
    }
}

export default Network;
