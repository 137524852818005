import React from "react";

import "./LandingStats.css";

const LandingStats = () => {
    return (
        <div className="landing-stats content">
            <h2>A History of Partnership</h2>
            <div className="section-content">
                <div className="stats row">
                    <div className="col">
                        <p className="num">37</p>
                        <p>counties served</p>
                    </div>
                    <div className="col">
                        <p className="num">673</p>
                        <p>reports filed</p>
                        <div className="live">LIVE</div>
                    </div>
                </div>
                <div className="reviews row">
                    <div className="review">
                        <p>“CADMUS has been so helpful in making my day to day work more efficient. Highly recommend!”</p>
                        <div className="signature">
                            <p>Larry Smith</p>
                            <p>Director of Broome County Medical</p>
                        </div>
                        <div className="profile">
                            <i className="fa-solid fa-user-doctor"></i>
                        </div>
                    </div>
                    <div className="review">
                        <p>“CADMUS has been so helpful in making my day to day work more efficient. Highly recommend!”</p>
                        <div className="signature">
                            <p>Larry Smith</p>
                            <p>Director of Broome County Medical</p>
                        </div>
                        <div className="profile">
                            <i className="fa-solid fa-user-doctor"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingStats;