import React, { useState, useEffect } from "react";
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
// import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const colorScale = {
    domain: ['general-emergent'],
    range: ['#E83838']
}

const viz = vl.markPoint({ // defines the type of Mark being used
    // Mark properties
    filled: true,
    stroke: false,
    size: 500,
    opacity: 0.25
})
    // Encoding uses Scales to assign data to Channels
    .encode(
        // this Scale assigns the data field 'transport_time_m'
        // and type 'Q' (Quantitative) to the Channel 'x' (x axis)
        vl.x().fieldQ('transport_time_m')
            .title('Transport Time (minutes)') // Channel title
            .axis({ tickCount: 5 }), //  this forces a certain # of ticks
            
        // this Scale assigns to the y axis
        vl.y().fieldQ('on_scene_to_end_odom')
            .title('Transport Distance (miles)'), // Channel title
            
        // this Scale assigns to color
        vl.color().fieldN('report_type')
            .title('Call Type') // Channel title
            .legend({ disable: true }) // this disables the legend
            .scale(colorScale), // a pre-defined color pallete
            
        // this Scale assigns to the tool tip (type of Signal [below])
        vl.tooltip().fieldN('report_id')
    )

// registration
vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const Graph = ({ graphParams }) => {

    const [jsonData, setJsonData] = useState(null);
    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    // function that gets multiple reports from the server
    const getSeveralReports = async (desiredNumber, reportOffset) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_ANALYTICS;
        const dict = {
            // uid: user.uid,
            uid: 4,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    useEffect(() => {
        getSeveralReports(400, 0) // # reports, offset
            .then((data) => {
                setJsonData(data);
                setMarks(viz
                    .data(data) // data set
                    .title('GRAPH') // title of View
                    .width(500) // width of data rectangle
                    .height(450) // height of data rectangle
                    .autosize({ type: 'fit' }) // tries to fit the entire chart into given W/H
                    .transform(
                        { // transforms a time in ms to a time in minutes
                        "calculate": "datum.scene_to_destination_time/60000",
                        "as": "transport_time_m"
                        },
                        // filter to remove data points with values of 0
                        {"filter": "datum.on_scene_to_end_odom > 0"},
                        // filter to remove data points with values above 60 minutes
                        {"filter": "datum.transport_time_m < 60"},
                        // filter to remove data points before a certain time
                        {"filter": "datetime(datum.creation_timestamp) > datetime(2024, 9, 20)"}
                    )
                );
                // console.log('marks set: ', marks);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);

    return (
        <>
            {jsonData && vegaView ? (
                // the View must be appended to the div
                <div ref={(div) => {
                    if (div && vegaView && div.children.length === 0) {
                        div.appendChild(vegaView);
                    }
                }} />
            ) : (
                // Otherwise, display a loading message
                <div>Loading...</div>
            )}
        </>
    );
};

export default Graph;