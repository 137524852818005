import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import DualColListElement from '../list_elements/DualColListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportTimesModule = ({user, reportId, isSmallScreen}) => {
    const [timesData, setTimesData] = useState(null);

    // function that gets one report's time data from the server
    const getOneReportTimes = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_TIMES;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        getOneReportTimes(reportId)
            .then((data) => {
                setTimesData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);


    const createInfoListElement = (elementName, elementValue) => {
        return (
            elementValue !== null && elementValue !== 'null' ? ( // do not show value if null or 'null'
            <DualColListElement
            elementName={elementName}
            elementValue={elementValue}   
            />
            ) : null
        );
    };

    const createTimeListElement = (elementName, timeValue) => {
        if (Number.isInteger(timeValue)) {
            // convert time to hours, minutes, and seconds
            let timeValueHours = Math.floor(timeValue / 3600000); // times recorded in milliseconds, convert to hours
            let timeValueMinutes = Math.floor((timeValue % 3600000) / 60000); // convert remaining time to minutes
            let timeValueSeconds = Math.floor((timeValue % 60000) / 1000); // convert remaining time to seconds
            // if hours are 0, do not display them
            if (timeValueHours === 0 && timeValueMinutes === 0) {
                return createInfoListElement(elementName, (timeValueSeconds).toString() + ' seconds');
            } else if (timeValueHours === 0) {
                return createInfoListElement(elementName, (timeValueMinutes).toString() + ' minutes, ' + (timeValueSeconds).toString() + ' seconds');
            } else {
                return createInfoListElement(elementName, (timeValueHours).toString() + ' hours, ' + (timeValueMinutes).toString() + ' minutes, ' + (timeValueSeconds).toString() + ' seconds');
            }
        } else if (timeValue === null) {
            return createInfoListElement(elementName, '-');
        } else {
            return null;
        }
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <h2 className='module-title'>Report Times</h2>
            {timesData ? (
                <div>
                    <div className="list-header">
                        <div className="small-div">
                            <div style={{fontWeight: "bold"}}> Time Identifier </div>
                        </div>
                        <div className="small-div">
                            <div style={{fontWeight: "bold"}}> Time Value </div>
                        </div>
                    </div>
                    {/* {Object.keys(timesData[0]).map((key) => (
                        createTimeListElement(key, timesData[0][key])
                    ))} */}

                    {createTimeListElement("Turnout Time", timesData[0]['reaction_time'])}
                    {createTimeListElement("Driving to Scene Time", timesData[0]['driving_to_scene_time'])}
                    {createTimeListElement("Total On-Scene Time", timesData[0]['total_on_scene_time'])}
                    {createTimeListElement("Scene to Destination Time", timesData[0]['scene_to_destination_time'])}
                    {createTimeListElement("Wall Time", timesData[0]['wall_time'])}
                    {createTimeListElement("Recovery Time", timesData[0]['recovery_time'])}

                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportTimesModule;