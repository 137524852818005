import React from 'react';
import './DualColListElement.css';

const DualColListElement = ({ elementName, elementValue }) => {
    
    return (
        <div className="list-element">
            <div className="small-div">
                <div className="element-name"> {elementName} </div>
            </div>
            <div className="small-div">
                <div className="element-value"> {elementValue} </div>
            </div>
        </div>
    );
};

export default DualColListElement;