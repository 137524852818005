import React from "react";

import "./LandingDemo.css";

const LandingDemo = () => {
    return (
        <div className="landing-demo" id="demo">
            <div className="content">
                <h2>See Our Tool in Action</h2>
                <div className="section-content">
                    <iframe src="https://www.youtube.com/embed/og1LWE2TQxY"></iframe>
                </div>
            </div>
        </div>
    )
}

export default LandingDemo;