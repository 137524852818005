import React from "react";

import './LandingIntro.css';

const LandingIntro = () => {
    return (
        <div className="landing-intro">
            <div className="content">
                <div className="questions">
                    <span className="question">
                        <h5>Are you...</h5>
                        <p>a <span>Pre-Billing</span> or <span>Quality Assurance</span> specialist overwhelmed by the volume of reports coming in?</p>
                    </span>
                    <span className="question">
                        <h5>Or...</h5>
                        <p>an <span>EMS Director</span> or <span>Admin</span> that wants to better understand agency data and performance?</p>
                    </span>
                </div>
                
                <h3>CADMUS can help with that.</h3>

                <div className="features">
                    <div className="card">
                        <i className="fa-solid fa-upload"></i>
                        <h4>Seamless <br/> Integration</h4>
                        <p>Connect your ePCR software to our secure tool for automatic report upload</p>
                    </div>
                    <div className="card">
                        <i className="fa-solid fa-display"></i>
                        <h4>Comprehensive PCR Validation</h4>
                        <p>Never miss another provider error with our finely tuned quality dashboard</p>
                    </div>
                    <div className="card">
                        <i className="fa-solid fa-file"></i>
                        <h4>Essential <br/> Reporting</h4>
                        <p>Improve your practices with evidence-based clinical measures</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LandingIntro;