import React from "react";

import "./LandingFooter.css";

const LandingFooter = () => {
    return (
        <footer>
            <div className="content">
                <p>Copyright © CADMUS Analytics, Inc. 2024</p>
                <div className="icons">
                    <a href="mailto:brad@cadmusanalytics.com">
                        <i className="fa-solid fa-envelope"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/cadmus-ai/">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://www.youtube.com/@CadmusEMS">
                        <i className="fa-brands fa-youtube"></i>
                    </a>
                </div>
                
            </div>
        </footer>
    )
}

export default LandingFooter;