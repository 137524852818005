import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportNarrativeModule = ({user, reportId, isSmallScreen}) => {
    const [reportData, setReportData] = useState(null);

    // function that gets one reports from the server
    const getOneReport = async (reportId) => {
        const url = URLS.GET_ONE_REPORT;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        getOneReport(reportId)
            .then((data) => {
                setReportData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div>
                <h2 className='module-title'>Narrative</h2>
            </div>
            {reportData ? (
                <div className="list-element">
                    <div className="small-div">
                        <div className="element-title" style={{ lineHeight: '2' }}> {reportData[0].narrative} </div>
                    </div>
                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportNarrativeModule;