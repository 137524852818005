import React, { useState } from "react";

import LandingHeader from "../LandingHeader";
import LandingFooter from "../LandingFooter";

import "./FaqPage.css";

import data from "./faq.json";

const FaqPage = () => {
    const [isOpenArr, setIsOpenArr] = useState(Array(data.length).fill(false));

    const handleOpen = (index) => {
        const newArr = [...isOpenArr];
        newArr[index] = !newArr[index];
        setIsOpenArr(newArr);
        console.log(newArr[index])
    }

    return (
        <>
            <LandingHeader page="faq" />
            <div className="faq-page content">
                <div className="standard-title">
                    <h1>Frequently Asked Questions</h1>
                    <span>
                        <span>Can’t find what you’re looking for? </span>
                        <a href="mailto:contact@cadmusanalytics.com">Contact us</a>
                        <span> for more information!</span>
                    </span>
                </div>
                <hr className="post-title"/>


                <div className="faq-entries">
                    <h2>Integration and Compatibility</h2>
                    <div className="section-content">
                        { data.map((item, i) => (
                            <div className="qna">
                                <div className="question">
                                    <p>{item.question}</p>
                                    <i className={isOpenArr[i] ? "fa-solid fa-chevron-down open" : "fa-solid fa-chevron-down"} onClick={e => handleOpen(i)}></i>
                                </div>
                                <div className={isOpenArr[i] ? "answer open" : "answer"}>
                                    <p>{item.answer}</p>
                                </div>
                                {(i != data.length - 1) ? (<hr />) : ""}
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
            <LandingFooter />
        </>
    )
}

export default FaqPage;