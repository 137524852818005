import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import DualColListElement from '../list_elements/DualColListElement.js';
// import './ReportListElement.css';
import './Module.css';
import { timeout } from 'd3';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const bypass = process.env.REACT_APP_BYPASS_LOGIN;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportStatusModule = ({user, reportId, isSmallScreen}) => {
    const [reportData, setReportData] = useState(null);
    const [readStatus, setReadStatus] = useState(null);
    const [finishedStatus, setFinishedStatus] = useState(null);

    const handleReadButtonClick = () => {
        // if readStatus is true, change to false
        console.log('readStatus changed');
        if (readStatus) {
            setReadStatus(0);
        } else {
            setReadStatus(1);
        }
        // update state in database
    };

    const handleFinishedButtonClick = () => {
        // if readStatus is true, change to false
        console.log('finishedStatus changed');
        if (finishedStatus) {
            setFinishedStatus(0);
        } else {
            setFinishedStatus(1);
        }
        // update state in database
    };

    // function that gets one reports from the server
    const getOneReport = async (reportId) => {
        const url = URLS.GET_ONE_REPORT;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    const updateReadFinishedStatus = async (reportId, readStatus, finishedStatus) => {
        const url = URLS.UPDATE_REPORT_STATUS;

        let currentReadStatus = readStatus ? 'read' : 'unread';
        let currentFinishedStatus = finishedStatus ? 'finished' : 'unfinished';

        const dict = {
            uid: user.uid,
            report_id: reportId,
            read_status: currentReadStatus,
            finished_status: currentFinishedStatus,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    useEffect(() => {
        getOneReport(reportId)
            .then((data) => {
                setReportData(data);
                setFinishedStatus(data[0].report_marked_finished);
                // if report_marked_read is false, set readStatus to read
                if (data[0].report_marked_read === 0) {
                    if (bypass !== 'true') { // only change state if no bypass
                        setReadStatus(1);
                    } else {
                        setReadStatus(0);
                    }
                } else {
                    setReadStatus(1);
                }
                
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    // on every readStatus or finishedStatus change, print the change
    useEffect(() => {
        if (readStatus !== null && finishedStatus !== null) {
            console.log('Read Status:', readStatus);
            console.log('Finished Status:', finishedStatus);
            updateReadFinishedStatus(reportId, readStatus, finishedStatus);
        }
    }, [readStatus, finishedStatus]);

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div>
                <h2 className='module-title'>Report Status</h2>
            </div>
            {reportData ? (
                <>
                    <DualColListElement
                        elementName={'Read Status:'}
                        elementValue={readStatus ? 'This report has been read' : 'This report has not been read'}
                    />
                    <button className="enter-button" style={{marginBottom: '10px'}} onClick={() => handleReadButtonClick(reportId, readStatus)}>{readStatus ? 'Change status to unread' : 'Change status to read'}</button>
                    <DualColListElement
                        elementName={'Finished Status:'}
                        elementValue={finishedStatus ? 'This report has been finished' : 'This report has not been finished'}
                    />
                    <button className="enter-button" onClick={() => handleFinishedButtonClick(reportId, finishedStatus)}>{finishedStatus ? 'Change status to unfinished' : 'Change status to finished'}</button>
                </>
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportStatusModule;