import React from 'react';
import './Tag.css';

const MedicTag = ({ medicNumber, isAuthor, isButton, onButtonClick }) => {
    
    return (
        <div>
            {isButton ? (
                <button className={`tag-button ${isAuthor ? 'author' : 'medic'}`}>
                    {medicNumber}
                </button>
            ) : (
                <div className={`tag ${isAuthor ? 'author' : 'medic'}`}>
                    {medicNumber}
                </div>
            )}
        </div>
    );
};

export default MedicTag;
