import React, {useState} from "react";
import Network from '../../../models/Network.js'; 

import "./LandingContact.css";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const LandingContact = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [agencyName, setAgencyName] = useState('');
    const [agencyState, setAgencyState] = useState('');
    const [email, setEmail] = useState('');
    var [errorMessage, setErrorMessage] = useState(''); 

    const StatusCodes = require('../../../status-codes/status-codes.js')
    const ErrorCodes = require('../../../error-codes/error-codes.js')

    const validateInputs = (dict) => {
        let emailTest = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!dict.first_name || !dict.agency_name || !dict.email || !dict.agency_state) {
            return "please check that all fields have been filled out";
        } else if (!emailTest.test(dict.email)) {
            return "please check that you have entered a valid email"
        }
        return ""; // no error msg
    }


    const handleSubmit = async (e) => {
        e.preventDefault(); // prevents refresh upon submitting
        
        var dict = {
            first_name: firstName,
            last_name: lastName,
            agency_name: agencyName,
            agency_state: agencyState,
            email: email,
            CLIENT_ACCESS_KEY: key
        }
        console.log(dict);
        var errorMsg = validateInputs(dict);
        if (errorMsg) {
            console.log("Error - Failed to Add Form");
            document.getElementById("msg").innerText = "We were unable to submit your information - " + errorMsg;
            document.getElementById("msg").style.color = "#E83838";
            return;
        }

        const url = URLS.STORE_CUSTOMER_FORM;
        await Network.post(url, dict, async (response) => {
            const responseData =  response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                console.log("Form Successfully Added");
                document.getElementById("msg").innerText = "Thank you for providing us your contact information! We will reach out within the next 2-3 business days."
                document.getElementById("msg").style.color = "white";
                // clear form upon successful submission
                document.getElementById("firstName").value = "";
                setFirstName("");
                document.getElementById("lastName").value = "";
                setLastName("");
                document.getElementById("agencyName").value = "";
                setAgencyName("");
                document.getElementById("agencyState").value = "";
                setAgencyState("");
                document.getElementById("email").value = "";
                setEmail("");
                document.getElementById("submitBtn").disabled = true;
                
                const url = URLS.SEND_CONTACT_EMAIL;
                await Network.post(url, dict, async(response) => {
                    const responseData = response.data;
                    const status = response.status;

                    if (Network.checkValidStatusAndResponse(response)) {
                        console.log("Contact info successfully sent");
                    } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                        console.log("Server Error - Failed to Send Contact Info");
                    } else {
                        console.log("Error while sending contact info");
                    }

                })

                setTimeout(function() {
                    document.getElementById("submitBtn").disabled = false; // executes after 2 sec to prevent spam
                }, 2000);

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error - Failed to Add Form");
                document.getElementById("msg").innerText = "We were unable to submit your information. Please check to make sure you have filled out all fields correctly."
                document.getElementById("msg").style.color = "#E83838";
            } else {
                console.log('No Valid Response or Error')
                document.getElementById("msg").innerText = "We were unable to submit your information. Please check to make sure you have filled out all fields correctly."
                document.getElementById("msg").style.color = "#E83838";
            }
        })
        document.getElementById("submitBtn").blur(); // removes focus, resetting color
    }

    const USStateCodes = [
        "AL", "AK", "AZ", "AR", "AS", "CA", "CO", "CT", "DE", "DC", 
        "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY",
        "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE",
        "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK",
        "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "TT", "UT",
        "VT", "VA", "VI", "WA", "WV", "WI", "WY"];
    const USStateNames = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "American Samoa", "California", "Colorado", "Connecticut", "Delaware", "District of Columbia", 
        "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", 
        "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota"," Northern Mariana Islands", "Ohio", "Oklahoma", 
        "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Trust Territories", "Utah", 
        "Vermont", "Virginia", "Virgin Islands", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ];

    return (
        <div className="landing-contact">
            <div className="content">
                <h2>Interested in Learning More?</h2>
                <div className="section-content">
                    <p className="desc">Connect with us to learn more or schedule a demo!</p>
                    {/* <a className="email" href="mailto:brad@cadmusanalytics.com">
                        <i className="fa-solid fa-envelope"></i>
                        Email us 
                    </a> */}

                    <form className="form" id="form">
                        <div className="row name">
                            <input 
                            className="first" 
                            placeholder="FIRST NAME"
                            pattern="[A-Za-z]"
                            value={firstName}
                            id="firstName"
                            onChange={(e) => setFirstName(e.target.value)}
                            />
                            <input 
                            className="last" 
                            placeholder="LAST NAME"
                            value={lastName}
                            id="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>

                        <div className="row location">
                            <input 
                            className="agency" 
                            placeholder="AGENCY NAME"
                            value={agencyName}
                            id="agencyName"
                            onChange={(e) => setAgencyName(e.target.value)}
                            />
                            <select
                            value={agencyState}
                            id="agencyState"
                            onChange={(e) => setAgencyState(e.target.value)}
                            >
                                <option value="">STATE</option>
                                {USStateCodes.map((stateCode, id) => (
                                    <option value={USStateNames[id]}>{stateCode}</option>
                                ))}
                            </select>
                        </div>
                        
                        <input 
                        className="email" 
                        placeholder="EMAIL"
                        value={email}
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <input type="submit" className="submit-btn" value="Submit" onClick={handleSubmit} id="submitBtn"/>
                        <p className="msg" id="msg"></p>
                    </form>
                </div>    
            </div>

        </div>
    )
};

export default LandingContact;