import React from "react";
import { arc } from "d3";

const width = 500;
const height = 500;
const centerX = width / 2;
const centerY = height / 2;
const strokeWidth = 20;
const eyeOffsetX = 80;
const eyeOffsetY = 50;
const eyeRadius = 30;

const mouthArc = arc()
    .innerRadius(150)
    .outerRadius(170)
    .startAngle(Math.PI / 2)
    .endAngle((Math.PI * 3) / 2);

const BackGroundCircle = ({ radius }) => {
    return (
        <circle
            r={radius}
            fill="yellow"
            stroke="black"
            strokeWidth={strokeWidth}
        />
    );
}

const Smiley = () => {
    return (
        <svg width={width} height={height}>
            <g transform={`translate(${centerX},${centerY})`}>
                {/* <circle
                    r={centerY - strokeWidth / 2}
                    fill="yellow"
                    stroke="black"
                    strokeWidth={strokeWidth}
                /> */}
                <BackGroundCircle radius={centerY - strokeWidth / 2} />
                <circle
                    cx={-eyeOffsetX}
                    cy={-eyeOffsetY}
                    r={eyeRadius}
                />
                <circle
                    cx={eyeOffsetX}
                    cy={-eyeOffsetY}
                    r={eyeRadius}
                />
                <path d={mouthArc()} />
            </g>
        </svg>
    );
}

export default Smiley;