import React, { useState, useEffect } from 'react';

import Header from '../Header.js';
import QiMeasuresGraphsBox from '../boxes/QiMeasuresGraphsBox.js';
import Footer from '../Footer.js';

const QualityImprovementPage = ({ user }) => {
    
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
        };
    
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header user={user} displayHomeButton={true} displayQiButton={false} displayQiImage={true}/>
            <div className='box-spacer'/>
            <QiMeasuresGraphsBox user={user} isSmallScreen={isSmallScreen}/>
            <Footer />
        </div>
    );
};

export default QualityImprovementPage;