import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <p>Copyright © Cadmus Health Analytics, Inc. 2024</p>
        </div>
    );
}

export default Footer;