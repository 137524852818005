import React from "react";

import './LandingAbout.css';

const LandingAbout = () => {
    return (
        <div className="landing-about content">
            <h2>Made by a Team of Nouns</h2>
            <div className="section-content">
                <p>This project started out as a senior design project at Georgia Tech – it has since grown into a movement to assist in improving EMS practices nation-wide. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                <button className="blue-btn">
                    Learn more about us
                    <i className="fa-solid fa-chevron-right"></i>
                </button>                
            </div>
        </div>
    )
};

export default LandingAbout;