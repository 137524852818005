import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import DualColListElement from '../list_elements/DualColListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportAiFlagsModule = ({user, reportId, isSmallScreen}) => {
    const [aiFlagsData, setAiFlagsData] = useState(null);
    const [allChecksData, setAllChecksData] = useState(null);

    // function that gets one report's ai flags from the server
    const getOneReportAiFlags = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_AI_FLAGS;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    // function that gets one report's time data from the server
    const getOneReportChecks = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_CHECKS;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        getOneReportAiFlags(reportId)
            .then((data) => {
                setAiFlagsData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        getOneReportChecks(reportId)
            .then((results) => {
                setAllChecksData(results);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    const createInfoListElement = (elementName, elementValue) => {
        return (
            elementValue !== null && elementValue !== 'null' ? ( // do not show value if null or 'null'
            <DualColListElement
            elementName={elementName}
            elementValue={elementValue}   
            />
            ) : null
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <h2 className='module-title'>Report AI Flags</h2>
            {aiFlagsData && allChecksData ? (
                <div>
                    <div className="list-header">
                        <div className="small-div">
                            <div style={{fontWeight: "bold"}}> Title </div>
                        </div>
                        <div className="small-div">
                            <div style={{fontWeight: "bold"}}> Suggestion </div>
                        </div>
                    </div>

                    {aiFlagsData.map((flag) => (
                        createInfoListElement(flag.title, flag.suggestion)
                    ))}

                    {allChecksData.map((checkData) => (
                        // if checkData.result is 0, create a list element for it
                        checkData['result'] === 0 &&
                        createInfoListElement(
                            `Error detected with: ${checkData.test_name}`, 
                            checkData.failure_mode ? <span><u>{checkData.failure_mode}</u> {checkData.issue_message}</span> : checkData.issue_message
                        )
                    ))}

                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportAiFlagsModule;