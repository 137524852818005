import React, { useState, useEffect } from 'react';

import Header from '../Header.js';
import SortReportsBox from '../boxes/SortReportsBox.js';
import HomeGraphsBox from '../boxes/HomeGraphsBox.js';
import Footer from '../Footer.js';

const ServiceHome = ({ user }) => {
    
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
        };
    
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {user.subscriptions.includes('CADMUS_QI') ?
                <Header user={user} displayHomeButton={false} displayQiButton={true}/> :
                <Header user={user} displayHomeButton={false} displayQiButton={false}/>
            }
            <div className='box-spacer'/>
            <HomeGraphsBox user={user} isSmallScreen={isSmallScreen}/>
            <SortReportsBox user={user} isSmallScreen={isSmallScreen}/>
            <Footer />
        </div>
    );
};

export default ServiceHome;