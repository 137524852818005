import React, { act, useEffect, useState } from 'react';
import './FilterListElement.css';

import FilterDropdown from './FilterListElement/FilterDropdown';

import TypeTag from "../tags/TypeTag";
import { filter } from 'd3';

const tagNames = {
    "non-emergent": "Non-Emergent",
    "non-acute": "Non-Acute/Other",
    "refusal": "Refusal",
    "cancelled": "Cancelled/No Patient",
    "general-emergent": "Emergent",
    "stroke": "Stroke",
    "circulatory": "Circ",
    "arrest": "Arrest",
    "stemi": "STEMI",
    "trauma": "Trauma",
    "respiratory": "Resp",
    "seizure": "Seizure",
    "syncope": "Syncope",
    "tbi": "TBI",
    "pediatrics": "Ped",
    "hypoglycemia": "LBG",
    "airway": "Airway",
    "asthma": "Asthma"
};
const sortedTagNames = Object.fromEntries(
    Object.entries(tagNames).sort()
);

const medicNames = {
    "2NF2HCLSNI": "Alex",
    "SDJ205NDG9": "Barry",
    "DK205LKFGJ": "Coral"
};

const dateOptions = {
    "all": "All time",
    "day": "Past Day",
    "week": "Past Week",
    "month": "Past Month",
    "year": "Past Year",
    "five-years": "Past 5 years",
    "custom": "Custom",
}

// setting the keys to true/false/both for both readOptions and finishedOptions does not work
// since clicking on the two dropdowns will affect each other due to having the same names
const readOptions = { 
    "read": "Read",
    "unread": "Unread",
    "both": "Read & Unread"
}

const finishedOptions = {
    "finished": "Finished",
    "unfinished": "Unfinished",
    "both": "Finished & Unfinished"
}

let filterDict = {}; // delete later

// const FilterListElement = ({ medicsData, filterDict, updateHandler, filterFields, initialFilterState }) => {
const FilterListElement = ({ medicsData, filterState, dictUpdateHandler, stateUpdateHandler, filterFields }) => {
    // Initialize medicsData and create medicsOptions
    medicsData = Array.isArray(medicsData) ? medicsData : [];
    const medicsOptions = {};
    for (const medic of medicsData) {
        medicsOptions[medic["crew_member_id"]] = medic["medic_name"];
    }

    // Set initial filter state conditionally
    const [tagTypes, setTagTypes] = useState(filterState.tagTypes || []);
    const [medics, setMedics] = useState(filterState.medics || []);
    const [date, setDate] = useState(filterState.date || ['all']);
    const [read, setRead] = useState(filterState.read || ['both']);
    const [finished, setFinished] = useState(filterState.finished || ['both']);

    // State for applied filters
    const [appliedTags, setAppliedTags] = useState(filterDict["reportType"] ? filterDict["reportType"].split(",") : []);
    const [appliedMedics, setAppliedMedics] = useState(filterDict["crewMemberId"] ? filterDict["crewMemberId"].split(",") : []);
    
    // Variable to control all dropdowns
    const [dropdown, setDropdown] = useState(null);

    const handleApplyFilters = () => {
        // console.log("Applying new filters: " + tagTypes + ", " + medics + ", " + date + ", " + read + ", " + finished);
        // let newFilterDict = {...filterDict};
        let newFilterDict = {};
        newFilterDict.readStatus = convertBooleans(read[0]);   
        newFilterDict.finishedStatus = convertBooleans(finished[0]);
        newFilterDict.reportType = tagTypes.join(",") || null; // || null added, perhaps not needed
        newFilterDict.crewMemberId = medics.join(",") || null;
        let dateRange = convertDate(date[0]); // date is in an array length 1
        newFilterDict.lowerDate = dateRange[0] ? formatDateToString(dateRange[0]) : null;
        newFilterDict.upperDate = dateRange[1] ? formatDateToString(dateRange[1]) : null;  
        // console.log('New filter dict:', newFilterDict);
        dictUpdateHandler(newFilterDict);
        if (stateUpdateHandler) {
            stateUpdateHandler({tagTypes, medics, date, read, finished});
        }

        setAppliedTags(tagTypes);
        setAppliedMedics(medics);

        // reset dropdowns
        console.log("setting dropdown");
        setDropdown(!dropdown);
    }

    const convertBooleans = (value) => {
        //convert from string values (needed for dictionary keys) too booleans to return
        if (value === "both") {
            return null;
        } else if (value === "read" || value === "finished") {
            return true;
        } 
        return false;
    }

    const convertDate = (range) => {
        const curr = new Date();
        var dateRange = [new Date(0), curr];
        if (range === "all") {
            return dateRange;
        } else if (range === "day") {
            dateRange[0] = new Date(curr.getTime() - (24 * 60 * 60 * 1000)) // subtract 24hrs from today
        } else if (range === "week") {
            dateRange[0] = new Date(curr.getTime() - (7 * 24 * 60 * 60 * 1000)) // subtract 7 days from today
        } else if (range === "month") {
            dateRange[0] = new Date(curr);
            dateRange[0].setUTCMonth(curr.getUTCMonth() - 1);
            // Handle month overflow if necessary, ie Feb 31
            if (dateRange[0].getUTCMonth() === curr.getUTCMonth()) {
                dateRange[0].setUTCDate(0); // set to last day of previous month
            }
        } else if (range === "year") {
            dateRange[0] = new Date(curr);
            dateRange[0].setUTCFullYear(curr.getUTCFullYear() - 1);
        } else if (range === "five-years") {
            dateRange[0] = new Date(curr);
            dateRange[0].setUTCFullYear(curr.getUTCFullYear() - 5);
        }
        return dateRange;
    }

    function formatDateToString(date) {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {
        if (filterState) {
            handleApplyFilters();
        }
    }, []);

    return (
        <div className="list-element filter-list-element">
            <div className='row'>
                <div className='filters'>
                    {filterFields === 'reportsList' ? (
                        <FilterDropdown 
                            title="Type Tags" 
                            options={sortedTagNames} 
                            selected={tagTypes} 
                            updateSelected={setTagTypes} 
                            multiple={true}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {/* <FilterDropdown 
                        title="Medics" 
                        options={medicsOptions} 
                        selected={medics} 
                        updateSelected={setMedics} 
                        multiple={true}
                    /> */}
                    {filterFields === 'analytics' ? (
                        <FilterDropdown 
                            title="Date Range" 
                            options={dateOptions} 
                            selected={date}
                            updateSelected={setDate} 
                            multiple={false}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {filterFields === 'reportsList' ? (
                        <FilterDropdown 
                            title="Read" 
                            options={readOptions} 
                            selected={read}
                            updateSelected={setRead} 
                            multiple={false}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {filterFields === 'reportsList' ? (
                        <FilterDropdown 
                            title="Finished" 
                            options={finishedOptions} 
                            selected={finished}
                            updateSelected={setFinished} 
                            multiple={false}
                            dropdown={dropdown}
                        />
                    ) : null}
                </div>
                <button className="submit" onClick={handleApplyFilters}>
                    {/* Apply <br/> Filters */}
                    <i className="only-i fa-solid fa-filter"></i>
                </button>
                {/* <i className="fa-solid fa-envelope"></i> */}
            </div>
            
            {filterFields === 'reportsList' ? (
                <div className='applied-tags'>
                    <div className='tags'>
                        {appliedTags.map((tag) => (
                            <TypeTag reportType={tag} isButton={false} />
                        ))}
                    </div>
                </div>
            ) : null}
            {/* <div className='medics'>
                <p>Included Medics: </p>
                    {appliedMedics.map((medic) => (
                        <span className='medic-tag'>{medic}</span>
                    ))}
            </div> */}
        </div>
    );
};

export default FilterListElement;