import React from 'react';
import './ReportListElement.css';

import TypeTag from '../tags/TypeTag.js';

const ReportListElement = ({ reportId, reportNumber, incidentNumber, numberOfIssues, reportType, incidentTime, read, finished, onButtonClick }) => {
    
    const handleButtonClick = () => {
        onButtonClick(reportId);
    };
    
    // break up reportType, a string of values concatonated by commas, into an array
    const reportTypes = reportType.split(',');
    // console.log('reportTypes: ', reportTypes);

    const convertToLocaleString = (dateString) => {
        const options = { 
            hour: 'numeric', 
            minute: 'numeric', 
            hour12: true, 
            year: 'numeric', 
            month: 'numeric', 
            day: 'numeric'
        };
    
        // if datestring is undefined or null, return 'no data'
        if (dateString === undefined || dateString === null) {
            return 'no data';
        }

        // Print out unconverted date
        // console.log('original dateString:', dateString);
    
        // convert the date string to a valid ISO 8601 date string and add Z to the end to indicate UTC time
        dateString = dateString.replace(' ', 'T');
        dateString = dateString + 'Z';

        // now convert the date string (which represnets a date in GMT) to a date object
        const date = new Date(dateString);
        
        // Print new date
        // console.log('new date:', date); // this is printing out GMT as CDT
    
        return date.toLocaleString([], options);
    };

    // function that assigns a color to the incident time string based current shift
    // shifts are 24 hours long, beginning at 8am daily
    // there is a red, blue, and green shift and they repeat in that order
    const assignColor = (incidentTimeString) => {
        // make new date
        const incidentTime = new Date(incidentTimeString);

        // day of year funciton def (offset by 8 hours) - 7 right now because of daylight savings??? TODO: fix
        const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0) - 7 * 1000 * 60 * 60) / (1000 * 60 * 60 * 24));

        // compute day of year and print
        const incidentDay = dayOfYear(incidentTime);
        // console.log('incidentDay:', incidentDay);

        // mod by 3 and assign rgb color based on the result
        const shift = incidentDay % 3;
        let color = '';
        switch(shift) {
            case 0:
                color = 'red';
                break;
            case 1:
                color = 'blue';
                break;
            case 2:
                color = 'green';
                break;
            default:
                color = 'black';
        }

        return color;

    }

    return (
        <div className={read ? "list-element-read" : "list-element"}>
        {/* <div className="list-element-read"> */}
            <div className='medium-div'>
                {incidentNumber === null ? ( // if incidentNumber is null, display reportNumber
                    <div className="report-number">{reportNumber}</div>
                ) : (
                    <div className="report-number">{incidentNumber}</div>
                )}
            </div>
            <div className='small-div'>
                <div className="number-of-issues">{numberOfIssues}</div>
            </div>
            <div className='medium-div'>
            {reportTypes.map((type) => (
                // console.log('type: ', type)
                <TypeTag reportType={type} isButton={false}/>
            ))}
            </div>
            <div className='medium-div'>
                {/* <div className="incident-time" style={{ color: assignColor(convertToLocaleString(incidentTime)) }}>{convertToLocaleString(incidentTime)}</div> */}
                <div className="incident-time">{convertToLocaleString(incidentTime)}</div>
            </div>
            <button className="enter-button" onClick={handleButtonClick}>View Report</button>
        </div>
    );
};

export default ReportListElement;