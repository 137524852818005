import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LandingHeader from '../LandingHeader';
import LandingTitle from './LandingTitle';
import LandingIntro from './LandingIntro';
import LandingFeatures from './LandingFeatures';
import LandingDemo from './LandingDemo';
import LandingStats from './LandingStats';
import LandingContact from './LandingContact';
import LandingAbout from './LandingAbout';
import LandingFooter from '../LandingFooter';
// import Footer from '../../Footer';


const LandingPage = () => {
    const navigate = useNavigate();

    const handleLoginNav = async () => {
        navigate('/login'); 
    };

    return (
        <>
            <LandingHeader page="services"/>
            <LandingTitle />
            <LandingIntro />
            <LandingFeatures />
            <LandingDemo />
            <LandingStats />
            <LandingContact />
            <LandingAbout />
            <LandingFooter />
            {/* <Footer /> */}
        </>
    )
}

export default LandingPage;