import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import FilterListElement from '../list_elements/FilterListElement.js';
import GraphApiMultimodal from '../graphics/GraphApiMultimodal.js';
import GraphReportsScatter from "../graphics/GraphReportsScatter.js";
import GraphReportsBar from "../graphics/GraphReportsBar.js";
import GraphReportsTS from "../graphics/GraphReportsTS.js";
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

// function that takes in the reports JSON and manipulates the 'report_type' field to only be of the primary types
const consolidatePrimaryReportType = (reportArray) => {
    // for each object in the jsonData array, convert the report_type field from a comma-separated string to an array of strings
    reportArray.forEach((report) => {
        const temp = report.report_type.split(','); // TODO: remove
        // console.log('temp:', temp);
        // iterate through temp and upon an instance of 'general-emergent', set report_type to 'general-emergent'
        temp.forEach((type) => {
            if (type === 'general-emergent') {
                report.report_type = 'general-emergent';
            } else if (type === 'cancelled') {
                report.report_type = 'cancelled';
            } else if (type === 'non-emergent') {
                report.report_type = 'non-emergent';
            } else if (type === 'non-acute') {
                report.report_type = 'non-acute';
            } else if (type === 'refusal') {
                report.report_type = 'refusal';
            }
        })
    });

    return reportArray;
}

const ReviewGraphsModule = ({user, isSmallScreen}) => {

    const [jsonData, setJsonData] = useState(null);
    const [filterDict, setFilterDict] = useState(null);
    
    const graphParams = {
        width: 400,
        height: 400
    }

    // function that gets multiple reports from the server
    const getSeveralReportsFiltered = async (desiredNumber, reportOffset, filterDict) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_FILTERED;
        const dict = {
            uid: user.uid,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            filter_dict: filterDict,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    // get reports from the server
    useEffect(() => {
        // get the reports based on the current filterDict
        if (filterDict) {
            getSeveralReportsFiltered(1000, 0, filterDict) // max 1000 reports, no offset
                .then((data) => {
                    consolidatePrimaryReportType(data); // consolidate report types
                    setJsonData(data); // TODO: add protection against API failure
                    console.log('Setting JSON data:', data);
                })
                .catch((error) => {
                    console.log('Error:', error);
                });
            }
    }, [filterDict]);

    
    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <FilterListElement
                filterState={
                    {date: ['week']}
                }
                dictUpdateHandler={setFilterDict}
                filterFields="analytics"
            />
            <div>
                <h2 className='module-title'>Recent Reports Overview</h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {/* TODO should this div be a list element? */}
                {jsonData ? (
                    <div>
                        <GraphApiMultimodal data={jsonData} graphParams={graphParams}/>
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </div>
    );
}

export default ReviewGraphsModule;