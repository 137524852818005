class ErrorCodes {
    static OK = "2000"

    //Registration Error Codes
    static EMAIL_IN_USE = "2001"
    static EMAIL_INVALID = "2014"
    static USERNAME_IN_USE = "2002"
    static USER_ALREADY_CREATED = "2006"

    //MFA Error codes
    static CODE_NOT_VALID = "2019"

    //Login Error Codes
    static USER_NOT_FOUND = "2003"
    static PASSWORD_INCORRECT = "2004"

    //Profile Image Codes
    static FILE_UPLOAD_FAILED = "2005"

    //Reauthenticatin needed
    static REAUTHENTICATION_NEEDED = "2007"

    //QR Code Scanning
    static EMAIL_DOMAIN_NOT_ALLOWED = "2008"

    static INVALID_TOKEN = "2011"

    //Version Control
    static INVALID_CLIENT_VERSION = "2012"

    //Verification Codes
    static INCORRECT_VERIFICATION_CODE = "2013"

    //Handling Data
    static INVALID_DATA_FORMAT = "2015"

    // Handling uploads
    static INVALID_FILE = "2016"

    // Failed Query
    static SYSTEM_FAILED = "2017"

    // In-Progress Query
    static IN_PROGRESS = "2018"
    
}

module.exports = ErrorCodes;