import React, { useState, useEffect } from "react";
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
// import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const brush = vl.selectInterval().encodings('x');
const click = vl.selectMulti().encodings('color');

const scale = {
    domain: ['general-emergent', 'cancelled', 'non-emergent', 'non-acute', 'refusal'],
    range: ['#e83838', '#222', '#lightgray', '#14aa1e', '#FFFF00']
}

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 12,
            fill: '#000'
        },
        'guide-title': {
            fontSize: 18,
            fill: '#000'
        }
    },
    legend: {
        disable: true
    }
};

const pointSize = 400;

const plot1 = vl.markPoint({ // defines the type of Mark being used
    // Mark properties
    filled: true,
    stroke: false,
    size: pointSize,
    opacity: 0.4
})
    .encode(
        vl.color().value('lightgray')
            .if(brush, vl.color().fieldN('report_type').scale(scale).title('Report Type')),
        //   vl.size().fieldQ('precipitation').scale({domain: [-1, 50], range: [10, 500]}).title('Precipitation'),
        //   vl.size().fieldQ('response_time').title('Response Time'),
        //   vl.order().fieldQ('response_time').sort('descending'),
        vl.x().fieldT('unit_back_in_service')
            // .timeUnit('monthdate') // make the time unit month and date - this gets rid of the time of day
            .timeUnit({ unit: 'monthdate', utc: false }) // Display month and date in local time
            // add scale -> padding: 20
            .scale({padding: pointSize/40})
            .axis({title: 'Date', format: '%b the %dth'}),
        //   vl.y().fieldT('creation_timestamp')
        vl.y().fieldT('unit_back_in_service')
            // .timeUnit('utchours') // make display time of day by hour
            // .timeUnit('hours') // make display time of day by hour (NON UTC)
            .timeUnit({ unit: 'hours', utc: false }) // Display time of day in hours (local time)
            .scale({padding: pointSize/40})
            .axis({title: 'Time of Day', format: '%I %p'}),
        vl.tooltip().fieldN('incident_number')
        // vl.tooltip().fieldT('unit_back_in_service')
        //     .timeUnit({ unit: 'monthdatehoursminutes', utc: false })
            // .timeUnit('hours')
    )
    .width(700) // sized for normal, 1080p with no zoom
    .height(250)
    .select(brush)
    .transform(vl.filter(click));

const plot2 = vl.markBar()
    .encode(
      vl.color().value('lightgray')
        .if(click, vl.color().fieldN('report_type').scale(scale).title('Report Type')),
      vl.x().count()
        .axis({title: 'Number of Reports'}),
      vl.y().fieldN('report_type').scale({domain: scale.domain}).title('Report Type')
    )
    .width(700)
    .select(click)
    .transform(vl.filter(brush));

// registration
vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const GraphApiMultimodal = ({ data, graphParams }) => {

    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    useEffect(() => {
        setMarks(vl.vconcat(plot1, plot2)
            .data(data) // data set
            // .title('Reports Multimodal with Brush') // title of View
            .config(config)
            .autosize({contains: 'padding'})
        );
        console.log('marks are being set again')
    }, [data]);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
                console.log('Vega view set again');
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);

    return (
        <>
            {vegaView ? (
                // the View must be appended to the div
                <div ref={(div) => {
                    if (div && vegaView) {
                        div.innerHTML = ''; // Clear previous view
                        div.appendChild(vegaView); // Append the new view
                    }
                }} />
            ) : (
                // Otherwise, display a loading message
                <div>Loading...</div>
            )}
        </>
    );
};

export default GraphApiMultimodal;